export const laptops = [
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2017",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Pro - 2020",
    price: "£800",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2015",
    price: "£300",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2019",
    price: "£500",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "69",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2017",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2017",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2017",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/macbook-air.png"),
    name: "Macbook Air - 2017",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
];
