import React from 'react'

import { AiFillStar } from 'react-icons/ai'

const ProductCard = ({imgurl, name, price, blurp, reviews}) => {
  return (
    <div className='rounded-lg flex flex-col justify-center items-center p-4'>
      <img src={imgurl} alt='product' width={250} />
      <br />
      <div className='flex flex-col gap-3'>
        <div className='flex justify-between items-center font-semibold'>
          <h2>{name}</h2>
          <p>{price}</p>
        </div>
        <div>
          <p className='text-xs'>{blurp}</p>
          <div className='flex items-center py-2'>
            <AiFillStar className='text-green-400' />
            <AiFillStar className='text-green-400' />
            <AiFillStar className='text-green-400' />
            <AiFillStar className='text-green-400' />
            <AiFillStar className='text-green-400' />
            <span className='text-xs'>({reviews})</span>
          </div>
        </div>
        <button className='py-2 px-4 border-2 border-green-400 rounded-full hover:bg-green-400 text-sm ease-in-out duration-200'>Add To Card</button>
      </div>
    </div>
  )
}

export default ProductCard