export const blogData = [
    {
      blogTitle: "Lorem ipsum dolor sit amet consectetur. Blandit fermentum.",
      blogTags: ["Tag 1", "Tag 2"],
      blogDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor purus id ac, sit suspendisse sagittis. Nulla varius elementum a venenatis convallis arcu aliquam auctor sollicitudin. Aenean blandit lectus luctus odio",
      blogDate: "2023-07-06",
      blogLastUpdated: "5 minutes",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
    {
      blogTitle: "Fusce euismod dignissim varius. Nulla. Vitae.",
      blogTags: ["Tag 3", "Tag 4"],
      blogDescription: "Fusce euismod dignissim varius. Nulla vitae justo imperdiet finibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec quis placerat sem, at ultricies mi. Donec sit amet tristique mauris. Fusce bibendum a lectus at tristique.",
      blogDate: "2023-07-05",
      blogLastUpdated: "2 hours",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
    {
      blogTitle: "Phasellus at elementum diam. Quisque consectetur.",
      blogTags: ["Tag 5", "Tag 6"],
      blogDescription: "Phasellus at elementum diam. Quisque consectetur scelerisque facilisis. Cras eleifend felis nisl, sed iaculis erat hendrerit sed. Maecenas efficitur velit eu leo cursus ultrices. Nullam a rhoncus ex, nec vulputate orci.",
      blogDate: "2023-07-04",
      blogLastUpdated: "1 day",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
    {
      blogTitle: "Etiam tristique diam nec eros bibendum.",
      blogTags: ["Tag 7", "Tag 8"],
      blogDescription: "Etiam tristique diam nec eros bibendum, id mattis nisl fringilla. Duis lacinia, libero et efficitur cursus, ante est aliquam metus, a hendrerit leo metus ut purus. Nulla pellentesque purus vitae lacus pulvinar rhoncus.",
      blogDate: "2023-07-03",
      blogLastUpdated: "3 days",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
    {
      blogTitle: "Vestibulum ante ipsum primis in faucibus orci.",
      blogTags: ["Tag 9", "Tag 10"],
      blogDescription: "Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed semper lobortis metus vitae varius. Sed porttitor scelerisque magna et ultrices. Fusce vehicula nulla id tempor condimentum.",
      blogDate: "2023-07-02",
      blogLastUpdated: "1 week",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
    {
      blogTitle: "Curabitur dictum, purus et aliquam.",
      blogTags: ["Tag 11", "Tag 12"],
      blogDescription: "Curabitur dictum, purus et aliquam fringilla, erat elit consequat justo, non auctor neque odio sit amet elit. Integer malesuada lectus non massa consectetur, vitae congue risus semper. Sed eget gravida metus.",
      blogDate: "2023-07-01",
      blogLastUpdated: "2 weeks",
      blogImageUrl: "https://ecelectronics.com/wp-content/uploads/2020/04/Modern-Electronics-EC-.jpg",
    },
  ];
  