export const faqData = [
  {
    question: "What is LapnTab?",
    answer:
      "LapnTab is an electronics provider that specializes in offering a wide range of products, including iPhones, MacBooks, and more, to cater to your tech needs.",
  },
  {
    question: "What products does LapnTab offer?",
    answer:
      "LapnTab provides a variety of electronics, including iPhones, MacBooks, accessories, and related gadgets, ensuring a comprehensive selection for tech enthusiasts.",
  },
  {
    question: "Does LapnTab offer any warranties?",
    answer:
      "Yes, LapnTab typically provides warranties for its products, offering coverage for specific periods to protect against manufacturing defects and malfunctions.",
  },
  {
    question: "Can I trade in old devices with LapnTab?",
    answer:
      "Absolutely, LapnTab often offers trade-in programs where you can exchange your old devices for credit towards the purchase of new electronics, making upgrades more convenient.",
  },
  {
    question: "Is LapnTab an authorized Apple reseller?",
    answer:
      "Yes, LapnTab is an authorized reseller of Apple products, ensuring that you are getting genuine and authentic Apple devices and accessories.",
  },
];
