export const testimonialData = [
  {
    id: 1,
    testimonial: "LapnTab redefined my tech experience! The quality of their iPhones and MacBooks is unparalleled. I'm a satisfied customer and a LapnTab advocate!",
    imgUrl: require("../assets/testimonial1.png"),
    name: "Samuel Roberts",
    designation: "Tech Enthusiast",
  },
  {
    id: 2,
    testimonial: "I'm blown away by LapnTab's products! My MacBook purchase was seamless, and the performance is exceptional. LapnTab is my go-to for premium tech!",
    imgUrl: require("../assets/testimonial2.png"),
    name: "Michael Collins",
    designation: "Graphic Designer",
  },
  {
    id: 3,
    testimonial: "LapnTab's iPhones are top-notch! The sleek design and advanced features exceeded my expectations. Proud to be a LapnTab customer!",
    imgUrl: require("../assets/testimonial3.png"),
    name: "Emily Turner",
    designation: "Digital Nomad",
  },
  {
    id: 4,
    testimonial: "LapnTab transformed my work setup! The combination of iPhone and MacBook is a productivity powerhouse. LapnTab has won me over!",
    imgUrl: require("../assets/testimonial4.png"),
    name: "Daniella Mitchell",
    designation: "Entrepreneur",
  },
];
