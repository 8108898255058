import './ContactUs.css'

const Form = () => {

  return (
    <div>
      <form className="flex flex-col bg-white rounded-lg form-background p-6 m-4">
        <label>Full Name</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="John Doe"
        />
        <label>Organization Name</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="ABC Company"
        />
        <label>Email</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="email"
          placeholder="email@gmail.com"
        />
        <label>Contact Number</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="number"
          placeholder="+44 7666 111199"
        />
        <label>Strength of Organization</label>
        <select className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]">
          <option value="<500">100-500</option>
          <option value="<5000">500-5000</option>
          <option value=">5000">5000+</option>
        </select>
        <label>How did you hear about us?</label>
        <input
          className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"
          type="text"
          placeholder="E.g. Google, LinkedIn, Facebook"
        />
        <label>Message</label>
        <textarea className="p-2 border-[1px] border-solid border-[#EEEDF0] rounded my-2 md:w-[500px]"></textarea>
        <button
          className="bg-green-400 p-2 rounded my-2"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default Form;
