import React from "react";

import bannerImg from "../assets/products.png";

const Banner = () => {
  return (
    <div className="flex justify-center items-center py-6">
      <div className="w-11/12 md:flex justify-between items-center bg-pink-200 px-6 md:py-2 py-6 rounded-xl mt-10">
        <div>
          <h2 className="text-3xl font-bold uppercase">
            Grab Upto 50% Off on Headphones
          </h2>
          <br />
          <button className="py-2 px-4 border-2 border-green-400 rounded-full bg-green-400 text-sm ease-in-out duration-200">
            Shop Now
          </button>
        </div>
        <div>
          <img src={bannerImg} alt="banner" width={600} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
