export const cookieData = [
    {
      heading: "Cookie Notice",
      paragraphs: [
        "Welcome to TechExperts's Cookie Notice. This notice explains how we use cookies and similar technologies on our website (www.techexperts.com) to improve your browsing experience and analyze website usage. By continuing to use our website, you consent to the use of cookies as described in this notice."
      ]
    },
    {
      heading: "What are Cookies?",
      paragraphs: [
        "Cookies are small text files that are stored on your device when you visit a website. They help websites recognize your device, remember your preferences, and provide personalized content and features."
      ]
    },
    {
      heading: "Types of Cookies We Use",
      paragraphs: [
        "We use several types of cookies for different purposes: Essential cookies are necessary for the proper functioning of our website, enabling basic features and ensuring security. Analytics cookies help us analyze website traffic and user behavior to improve our website's performance and content. Functional cookies remember your preferences and choices to enhance your browsing experience. Advertising cookies track your online activities and tailor advertisements to your interests."
      ]
    },
    {
      heading: "Managing Cookies",
      paragraphs: [
        "You can control and manage cookies in your browser settings. While you may choose to block or delete cookies, please note that this action may affect certain features and functionality of our website. For more information on managing cookies, refer to your browser's help documentation."
      ]
    },
    {
      heading: "Updates to This Cookie Notice",
      paragraphs: [
        "We may update this Cookie Notice to reflect changes in our practices or legal requirements. We encourage you to review this notice regularly to stay informed about how we use cookies."
      ]
    },
    {
      heading: "Contact Us",
      paragraphs: [
        "If you have questions or concerns about our Cookie Notice, please contact us via Email: privacy@techexperts.com, or Phone: 0800 123 4567."
      ]
    }
  ];
  