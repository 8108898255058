import React from "react";
import Layout from "../layout/Layout";
import { BsArrowUpRight } from "react-icons/bs";
import { blogData } from "./BlogData";
import BlogCard from "../components/BlogCard";

const Blog = () => {
  return (
    <Layout>
      <div className="flex justify-center items-center w-full">
        <div className="flex flex-col md:w-3/4 w-5/6 justify-center items-center mt-12">
          <h3 className="text-2xl font-bold">Blog</h3>
          <br />
          <p className="text-center">
            Stay up-to-date with TechExpert activities happening all around the
            world.
          </p>
          <div className="md:flex my-12 items-center bg-slate-50 rounded-xl shadow-xl">
            <img
              src="https://www.mobiletechtalk.co.uk/wp-content/uploads/2020/10/mobiletechtalk.co_.uk-image3.jpeg"
              alt="blogImage"
              className="md:w-1/2"
            />
            <div className="md:w-1/2 p-6">
              <h3 className="hidden md:block">
                TechExpert becomes the leading name in AI technology.
              </h3>
              <h4 className="md:hidden block">
                TechExpert becomes the leading name in AI technology.
              </h4>
              <div className="flex gap-2 my-4">
                <span className="bg-green-100 rounded-full py-2 px-3 text-xs">
                  Education
                </span>
                <span className="bg-green-100 rounded-full py-2 px-3 text-xs">
                  Learning Portal
                </span>
              </div>
              <p className="text-sm my-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor
                purus id ac, sit suspendisse sagittis. Nulla varius elementum a
                venenatis convallis arcu aliquam auctor sollicitudin. Aenean
                blandit lectus luctus odio
              </p>
              <span className="text-[#969696] text-xs">
                June 29, 2023 • 5 minutes
              </span>
              <br />
              <button className="flex items-center text-xs gap-2 my-3 text-[#0E0B3D] font-semibold">
                Read More
                <BsArrowUpRight />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-8">
            {blogData.map((blog, index) => (
              <BlogCard key={index} blog={blog} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
