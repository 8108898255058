import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const CookieModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (!isCookieAccepted) {
      setShowModal(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("cookieAccepted", "true");
    setShowModal(false);
  };

  return (
    <div
      className={`fixed inset-0 flex justify-center items-center ${
        showModal ? "flex" : "hidden"
      }`}
    >
      <div className="absolute inset-0 bg-gray-700 opacity-75"></div>
      <div className="fixed md:w-1/4 w-5/6 bg-white p-4 shadow-lg">
        <div className="flex justify-between items-center text-xl">
          <h2 className="font-semibold mb-2">Cookie Notice</h2>
          <button onClick={handleClose}>
            <AiOutlineCloseCircle className="text-red-500 mb-2" />
          </button>
        </div>
        <p className="mb-4">
          We use cookies to enhance your experience on our website. By
          continuing to use this site, you consent to the use of cookies.
        </p>
        <button
          className="px-4 py-2 w-full bg-green-500 text-white rounded hover:bg-green-600 duration-200 ease-in-out"
          onClick={handleClose}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieModal;
