import React from "react";
import Layout from "../layout/Layout";
import techimg from "../assets/techexperts.jpg";
import Faqs from "../components/Faqs";

const AboutUs = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col justify-center items-center bg-sky-50 my-3 p-6">
          <h2 className="p-2 text-2xl font-bold">
            About Tech<span className="text-green-400">Experts</span>
          </h2>
          <p className="p-2">UK's leading electronics solution providers</p>
        </div>
        <br></br>
        <div className="flex justify-center items-center">
          <div className="md:flex w-5/6">
            <img
              className="rounded-xl"
              src={techimg}
              alt="tech experts shop"
              width={500}
            />
            <div className="m-4 md:p-2 flex flex-col gap-3">
              <h1 className="text-2xl font-bold">About Us</h1>
              <p>
                TechExperts is a privately held company operating under the name
                MYTECHEXPERTS LTD (#14039121) and is registered in the United
                Kingdom (Located at 84 Town Square Basildon SS14 1BJ).
              </p>
              <p>
                Renowned as a premier smartphone and tech accessories retailer
                in the UK, TechExperts stands out with its extensive array of
                offerings and a widespread presence. As a dynamic force in the
                mobile industry, the company has gained rapid traction owing to
                its top-notch products and exceptional services.
              </p>
              <p>
                Leveraging years of industry expertise, TechExperts specializes
                in catering to the demands of mobile phones, tablets,
                smartwatches, and various other tech gadgets. At present, the
                company boasts a strong presence across 20 different UK
                locations.
              </p>
              <p>
                We take pride in delivering utmost customer satisfaction, both
                through our seamless online experience and immersive in-store
                services. You can find us in various different locations in the
                Greater London.
              </p>
            </div>
          </div>
        </div>
        <br></br>
        <div className="flex flex-col justify-center items-center bg-red-100 my-6 p-6">
          <h3 className="text-2xl font-bold">Our Vision</h3>
          <p className="text-center md:w-2/3 p-2">
            Over time, we've established a robust standing in the industry.
            However, our drive for growth remains unceasing as we strive to
            enhance our services and broaden our horizons. This commitment is
            aimed at providing elevated service excellence and setting elevated
            benchmarks for both our fresh and loyal patrons.
          </p>
          <p className="text-center md:w-2/3 p-2">
            Our efforts involve crafting a potent platform that embodies
            user-friendliness. This platform empowers our customers to access
            our offerings seamlessly, enabling them to make purchases
            conveniently and effortlessly, regardless of time or location.
          </p>
        </div>
        <br></br>
        <div className="flex flex-col justify-center items-center my-6">
          <h1 className="text-center text-2xl font-bold">What we do?</h1>
          <br></br>
          <div className="md:flex w-5/6">
            <div className="md:w-1/2 m-2 p-4 flex flex-col gap-3 bg-blue-50 rounded-xl">
              <p>
                Our selection encompasses an extensive array of smartphones and
                electronic devices, all available at unparalleled prices.
              </p>
              <p>
                Moreover, we extend our offerings to encompass gaming gadgets,
                AirPods, tablets, smartwatches, chargers, headphones, and an
                assortment of other accessories, catering comprehensively to
                your diverse requirements.
              </p>
              <p>
                Prior to showcasing our products for sale, each item undergoes a
                meticulous quality assessment to ensure utmost satisfaction.
              </p>
              <p>
                Rest assured, you will consistently discover options that align
                with your preferences, and we are dedicated to furnishing you
                with the most competitive prices available.
              </p>
            </div>
            <div className="md:w-1/2 m-2 p-4 flex flex-col gap-3 bg-blue-50 rounded-xl">
              <p>
                TechExpert ensures no-cost shipping for all orders surpassing
                £50, complemented by an express delivery service that guarantees
                the timely receipt of your order, regardless of your location
                across the UK.
              </p>
              <p>
                Any orders placed prior to 2 pm will be promptly dispatched on
                the same day from our warehouse.
              </p>
              <p>
                Our exclusive range consists solely of meticulously examined and
                tested products, each accompanied by a warranty for your peace
                of mind.
              </p>
              <p>
                Additionally, we furnish tax invoices for all transactions,
                further enhancing the transparency and credibility of our
                services.
              </p>
            </div>
          </div>
        </div>
        <Faqs />
        <div className="flex flex-col justify-center items-center gap-6 m-2 p-2">
          <div>
            <h2 className="text-2xl font-bold">
              Here's a traveller guide to us!
            </h2>
          </div>
          <div className="md:w-3/4 w-5/6 h-[500px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.911154566628!2d0.4531814900188799!3d51.569862233506974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8c740ea588bb1%3A0x523823968602c555!2sTech%20experts!5e0!3m2!1sen!2suk!4v1693406660655!5m2!1sen!2suk"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Tech Experts Location Map"
            ></iframe>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default AboutUs;
