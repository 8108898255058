import React from 'react'
import Layout from '../layout/Layout'
import { cookieData } from './CookieData'

const CookieNotice = () => {
  return (
    <div>
      <Layout>
        <div className='flex flex-col justify-center items-center w-full'>
          <div className='md:w-4/5 w-5/6 mt-8'>
            <h2 className='text-2xl font-bold'>Cookie Policy</h2>
            <div>
              {cookieData.map((data, index) => (
                <div className='py-4' key={index}>
                  <h6 className='text-xl font-semibold text-green-400'>{data.heading}</h6>
                  <br />
                  <p>{data.paragraphs}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default CookieNotice