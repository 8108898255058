import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TestimonialCard from "./TestimonialCard";
import { testimonialData } from "./TestimonialData";

const Testimonials = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="flex flex-col justify-center items-center m-8">
      <h3 className="text-center">
        Trusted by 21+ million customers around the world.
      </h3>
      <Carousel
        responsive={responsive}
        draggable={true}
        containerClass="carousel-container"
        arrows={true}
        className="md:w-5/6 w-full m-6"
      >
        {testimonialData.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            testimonial={testimonial.testimonial}
            imgUrl={testimonial.imgUrl}
            name={testimonial.name}
            designation={testimonial.designation}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
