import React from "react";
import Layout from "../layout/Layout";

const Login = () => {
  return (
    <div>
      <Layout>
        <div className="flex flex-col justify-center items-center gap-8 h-screen">
          <br></br>
          <form className="bg-white md:w-1/3 w-4/6 form-styles m-3 p-3">
            <label className="login-text">Sign in to your account</label>
            <input
              name="email"
              placeholder="Email Address"
              type="email"
              className="input-box text-sm"
            />
            <input
              name="password"
              placeholder="Password"
              type="password"
              className="input-box text-sm"
            />
            <button
              type="submit"
              className="px-4 py-2 my-6 hover:bg-green-400 rounded-full bg-green-300 ease-in-out duration-200"
            >
              Login
            </button>
            <button
              type="submit"
              className="underline text-sm duration-200 ease-in ease-out"
            >
              Forgot password?
            </button>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Login;
