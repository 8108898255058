import React from "react";
import ProductCard from "./ProductCard";

const Shop = ({title, products}) => {
  return (
    <div className="py-6 w-full flex justify-center items-center">
      <div className="w-11/12">
        <h2 className="text-2xl font-bold">{title}</h2>
        <br />
        <div className="grid md:grid-cols-4 grid-cols-2 md:gap-12 gap-2">
          {products.map((product, index) => (
            <ProductCard
              key={index}
              imgurl={product.imgurl}
              name={product.name}
              price={product.price}
              blurp={product.blurp}
              reviews={product.reviews}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
