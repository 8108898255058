import React from "react";
import Banner from "../components/Banner";
import Categories from "../components/Categories";
import Shop from "../components/Shop";
import Faqs from "../components/Faqs";
import ContactUs from "../components/ContactUs";
import Testimonials from "../components/Testimonials";
import Layout from "../layout/Layout";

import { laptops } from "../components/LaptopsData";
import { iphones } from "../components/IphoneData";

const Home = () => {
  return (
    <div>
      <Layout>
        <Banner />
        <Categories />
        <Shop title={"Laptops"} products={laptops} />
        <Shop title={"Phones"} products={iphones} />
        <Testimonials />
        <Faqs />
        <ContactUs />
      </Layout>
    </div>
  );
};

export default Home;
