import React from "react";
import CategoryCard from "./CategoryCard";

const data = [
  {
    background: "bg-sky-300",
    name: "iPhone",
    imgurl: require("../assets/iphone.png"),
  },
  {
    background: "bg-rose-300",
    name: "Laptop",
    imgurl: require("../assets/macbook.png"),
  },
  {
    background: "bg-pink-300",
    name: "Headphone",
    imgurl: require("../assets/headphone.png"),
  },
  {
    background: "bg-blue-300",
    name: "Monitors",
    imgurl: require("../assets/monitor.png"),
  },
  {
    background: "bg-yellow-300",
    name: "Watches",
    imgurl: require("../assets/watch.png"),
  },
];

const Categories = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center py-6">
      <br />
      <div className="w-11/12 flex flex-col justify-between">
        <h2 className="text-2xl font-bold">Shop By Categories</h2>
        <br />
        <div className="grid md:grid-cols-5 grid-cols-2 gap-4">
          {data.map((card, index) => (
            <CategoryCard
              key={index}
              background={card.background}
              name={card.name}
              imgurl={card.imgurl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
