/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { AiFillMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import "./ContactUs.css";
import Form from "./Form";

const ContactUs = () => {
  return (
    <div className="md:flex justify-center contact-background bg-slate-100">
      <div className="p-8 m-4 text-[#0E0B3D] md:w-1/2">
        <h2 className="text-3xl">Contact Us</h2>
        <h3 className="tracking-wide py-3">Get in touch</h3>
        <br />
        <p className="md:w-3/5">
          We always love hearing from our people, give us a call or drop a
          message and one of our representatives will be in touch with you
          shortly!
        </p>
        <br />
        <div className="flex flex-col gap-3 mt-4">
          <span className="flex items-center gap-4">
            <AiFillMail /> info@techexperts.com
          </span>
          <span className="flex items-center gap-4">
            <BsFillTelephoneFill /> +44 7891 928457
          </span>
        </div>
      </div>
      <div className="p-8">
        <Form />
      </div>
    </div>
  );
};

export default ContactUs;
