import React, { useState } from "react";
import Dropdown from "./Dropdown";
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { GoSearch } from "react-icons/go";
import { AiOutlineUser, AiOutlineShoppingCart } from "react-icons/ai";
import { Link } from "react-router-dom";

const categories = [
  {
    link: "/",
    linkName: "Smart Phones",
  },
  {
    link: "/",
    linkName: "Laptops",
  },
  {
    link: "/",
    linkName: "Headphones",
  },
  {
    link: "/",
    linkName: "Monitors",
  },
];

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="flex justify-center items-center w-full pt-4">
      <div className="flex justify-between items-center w-11/12 gap-3">
        <Link to="/">
          <h2 className="text-xl font-extrabold">
            Tech<span className="text-green-400">Experts</span>
          </h2>
        </Link>
        <div className="md:flex hidden justify-between items-center w-full mx-3">
          <ul className="flex gap-6 cursor-pointer text-sm">
            <Dropdown linkName="Categories" dropdownList={categories} />
            <Link to="/about-us">
              <li>About Us</li>
            </Link>
            <Link to="/my-shop">
              <li>Shop</li>
            </Link>
            <Link to="/blogs">
              <li>Blog</li>
            </Link>
          </ul>
          <div className="flex items-center gap-4 text-sm">
            <div className="flex items-center bg-slate-50 rounded-full px-6 py-2 cursor-pointer">
              <input type="search" placeholder="Search Products" />
              <GoSearch />
            </div>
            <Link
              to="/login"
              className="flex items-center gap-2 cursor-pointer"
            >
              <AiOutlineUser />
              <p>Account</p>
            </Link>
            <div className="flex items-center gap-2 cursor-pointer">
              <AiOutlineShoppingCart />
              <p>Cart</p>
            </div>
          </div>
        </div>
        <div className="flex relative">
          {!toggleMenu && (
            <HiMenuAlt4
              fontSize={28}
              className="md:hidden cursor-pointer"
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <AiOutlineClose
              fontSize={28}
              className="md:hidden cursor-pointer"
              onClick={() => setToggleMenu(false)}
            />
          )}
          {toggleMenu && (
            <ul
              className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
            flex flex-col justify-start items-start rounded-md bg-green-50 animate-slide-in"
            >
              <li className="text-xl w-full my-2">
                <AiOutlineClose onClick={() => setToggleMenu(false)} />
              </li>
              <div className="flex items-center bg-white border-2 border-green-300 rounded-full px-2 py-2 cursor-pointer">
                <input type="search" placeholder="Search Products" />
                <GoSearch />
              </div>
              <ul className="flex flex-col px-3 pt-6 text-md gap-4 text-[#535479] cursor-pointer">
                <Dropdown linkName="Categories" dropdownList={categories} />
                <Link to="/about-us">
                  <li>About Us</li>
                </Link>
                <Link to="/my-shop">
                  <li>Shop</li>
                </Link>
                <Link to="/blogs">
                  <li>Blog</li>
                </Link>
              </ul>
              <div className="flex flex-col px-3 gap-3 pt-3">
                <Link
                  to="/login"
                  className="flex items-center gap-2 cursor-pointer"
                >
                  <AiOutlineUser />
                  <p>Account</p>
                </Link>
                <div className="flex items-center gap-2 cursor-pointer">
                  <AiOutlineShoppingCart />
                  <p>Cart</p>
                </div>
              </div>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
