export const termsData = [
  {
    heading: "Introduction",
    paragraph:
      "Operating as TechExperts, these terms and conditions pertain to each visitor accessing our website www.techexperts.com. Should you have any inquiries regarding these terms, kindly get in touch with our representatives via email or call 0800 123 4567 from Monday to Saturday between 9am – 8pm. For quality and training purposes, all calls to this number will be recorded.",
  },
  {
    heading: "Your Agreement",
    paragraph:
      "By utilizing our website, you gain access to purchasing products and scheduling device repairs. You agree not to employ this platform for any unlawful purposes. Moreover, you pledge to refrain from utilizing the site in a manner that could be detrimental to its information or other content. Accuracy and correctness are expected when providing information for purchases or services. Our responses are based solely on the information you supply.",
  },
  {
    heading: "Communication Method",
    paragraph:
      "Recognizing that email is not a secure medium, you acknowledge that transmitting confidential information via email is undertaken at your own risk. We shall not be held accountable for any loss incurred. Furthermore, if you anticipate a direct response from the same email address to which you sent correspondence, please be aware that we avoid sending personal or sensitive information via email to our esteemed customers.",
  },
  {
    heading: "Rights & Authority",
    paragraph:
      "We retain the authority to monitor customers' interactions on our website. In cases of reported breaches of these terms or actions that demonstrate disrespect, harassment, or violation towards any individual or entity, we reserve the right to initiate investigations. Additionally, we possess the prerogative to withdraw any product listed on our website. Please note that the purchase of our products online is contingent upon you being 18 years or older; otherwise, your purchase will be nullified.",
  },
  {
    heading: "Placing Orders",
    paragraph:
      "Each order is contingent upon acceptance and product availability. Upon purchasing a product, you will promptly receive an order confirmation via email. A subsequent email will be dispatched when your item is on its way. If you select a phone product that is not in stock, you will be presented with the option to purchase an alternative product with comparable specifications or to await the availability of the desired item; otherwise, a refund will be issued. It's important to acknowledge that certain phone products may deviate slightly from their internal storage specifications. Should this occur, we will provide additional storage in the form of a memory card. Alternatively, you will be given the option to purchase a product with similar specifications or receive a refund.",
  },
];
