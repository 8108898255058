export const iphones = [
  {
    imgurl: require("../assets/iphone11.jpeg"),
    name: "iPhone 11",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone11.jpeg"),
    name: "iPhone 11 - Pro",
    price: "£800",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone12.jpeg"),
    name: "iPhone 12",
    price: "£300",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone13.jpeg"),
    name: "iPhone 13",
    price: "£500",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "69",
  },
  {
    imgurl: require("../assets/iphone14.jpg"),
    name: "iPhone 14",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone11.jpeg"),
    name: "iPhone 11",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone11.jpeg"),
    name: "iPhone 11",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
  {
    imgurl: require("../assets/iphone11.jpeg"),
    name: "iPhone 11",
    price: "£450",
    blurp: "Excellent condition, 9/10, perfect processor.",
    reviews: "65",
  },
];
