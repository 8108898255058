export const privacyData = [
  {
    heading: "Introduction",
    paragraphs: [
      "Welcome to TechExperts's Privacy Policy. This policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website (www.techexperts.com) and use our services. By accessing our website and providing your information, you consent to the practices described in this Privacy Policy.",
    ],
  },
  {
    heading: "Information We Collect",
    paragraphs: [
      "We may collect various types of information from you, including personal information such as name, email address, phone number, and billing information. Information about your device and internet connection, including IP address, browser type, and operating system. Usage data, such as pages visited, actions taken, and interactions with our website.",
    ],
  },
  {
    heading: "How We Use Your Information",
    paragraphs: [
      "We use the collected information for various purposes, including providing and improving our services, products, and website functionality. Communicating with you, responding to inquiries, and providing customer support. Sending you updates, promotions, and newsletters if you opt-in to receive them. Analyzing website usage and trends to enhance user experience and optimize content.",
    ],
  },
  {
    heading: "Information Sharing and Disclosure",
    paragraphs: [
      "We may share your personal information with trusted third-party service providers who assist in our operations. Legal authorities, when required by law or to protect our rights, privacy, safety, or property. Business partners or affiliates in connection with the services we provide.",
    ],
  },
  {
    heading: "Your Choices and Rights",
    paragraphs: [
      "You have the right to access and update your personal information. Opt-out of receiving promotional communications. Request the deletion of your account and personal data, subject to legal obligations. Object to the processing of certain information.",
    ],
  },
  {
    heading: "Data Security",
    paragraphs: [
      "We employ industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.",
    ],
  },
  {
    heading: "Changes to This Privacy Policy",
    paragraphs: [
      "We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.",
    ],
  },
  {
    heading: "Contact Us",
    paragraphs: [
      "If you have questions or concerns about our Privacy Policy, please contact us via Email: privacy@techexperts.com, or Phone: +44 7891 928457.",
    ],
  },
];
