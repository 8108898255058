import React from "react";

const CategoryCard = ({ background, name, imgurl }) => {
  return (
    <div className={`${background} rounded-xl flex justify-center items-center p-2 h-[230px]`}>
      <img src={imgurl} alt="" className="w-1/2" />
      <p className="text-center text-white text-xl font-bold p-2">{name}</p>
    </div>
  );
};

export default CategoryCard;
